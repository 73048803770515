<script lang="ts" setup>
import type { BannerPresetPackage } from "@/types";

const props = defineProps<{ banner: BannerPresetPackage }>();

const { t } = useT();
const { data: appInit } = useAppInitData();
const badgeLabel = computed(
	() => props.banner?.promoOfferPreset?.badgeLabel && parseJSON(props.banner.promoOfferPreset.badgeLabel)
);

const handleBannerClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInit.value?.depositsCount ?? 0
	});
	window?.$store?.gaCash?.deposit?.({
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", props.banner?.promoOfferPreset?.id);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.banner?.promoOfferPreset?.id}/promoOffer/`);
};
</script>
<template>
	<MBanner :image="banner?.promoOfferPreset?.imageBanner" :image-mobile="banner?.promoOfferPreset?.imageBannerMobile">
		<template #badge>
			<ACornerBadge
				v-if="badgeLabel"
				backgroundColor="var(--gimcheon)"
				class="banner-badge"
				modifiers="right"
				variant="accent"
			>
				<AText variant="tallinn" :modifiers="['normal']">
					<span v-if="badgeLabel.percent" class="badge-percent" v-html="badgeLabel.percent" />
					<span v-html="badgeLabel.text" />
				</AText>
			</ACornerBadge>
		</template>

		<div v-if="banner?.promoOfferPreset?.labelText" class="title" v-html="banner.promoOfferPreset.labelText" />

		<div class="subtitle">
			<AText
				v-if="banner?.promoOfferPreset?.coins || banner?.promoOfferPreset?.entries"
				variant="tanta"
				:modifiers="['lowercase']"
			>
				{{ t("Get") }}
			</AText>
			<AText
				v-if="banner?.promoOfferPreset?.coins"
				as="p"
				variant="torreon"
				:modifiers="['black']"
				class="text-coimbatore"
			>
				<NuxtImg src="/nuxt-img/cards/coins.png" width="28" height="28" alt="coins" />
				{{ numberFormat(banner.promoOfferPreset.coins) }}
			</AText>
			<AText
				v-if="banner?.promoOfferPreset?.entries"
				as="p"
				variant="torreon"
				:modifiers="['black', 'uppercase']"
				class="text-cixi"
			>
				<NuxtImg src="/nuxt-img/cards/entries.png" width="28" height="28" alt="entries" />
				{{ numberFormat(banner.promoOfferPreset.entries) }}
				<span class="free-label">{{ t("for free") }}</span>
			</AText>
		</div>

		<AButton variant="primary" size="md">
			<AText :modifiers="['uppercase']" variant="toledo" @click="handleBannerClick">
				{{ t("buy now") }} {{ appInit?.currencySymbol }}{{ banner?.money }}
			</AText>
		</AButton>
	</MBanner>
</template>
<style lang="scss" scoped>
.banner-badge.right.accent {
	--a-corner-badge-custom-background: var(--gimcheon);

	position: absolute;
	top: 0;
	right: 0;
	padding-bottom: gutter(0.5);

	&::before {
		transform: translate(0px, -14px) rotate(40deg);
	}

	.tallinn {
		display: flex;
		flex-direction: column;
	}

	.badge-percent {
		font-size: 12px;
		font-weight: 700;
	}
}

:deep(.banner-title) {
	font-weight: 700;
	font-size: 36px;
	text-transform: uppercase;

	@include media-breakpoint-down(md) {
		font-size: 20px;
		line-height: 21px;
	}
}

.subtitle {
	gap: gutter(1);

	p {
		display: flex;
		align-items: center;
		gap: gutter(0.5);
		margin: 0 gutter(0.5) 0 0;
	}

	.free-label {
		width: 32px;
		font-size: 12px;
		font-weight: 800;
		line-height: 14px;
	}

	@include media-breakpoint-down(md) {
		span,
		p {
			font-size: 16px;
			line-height: 21px;
		}

		img {
			width: 20px;
			height: 20px;
		}

		.free-label {
			width: auto;
			font-size: 16px;
		}
	}
}
</style>
